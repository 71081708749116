import { useState } from "react";
import { debounce } from "lodash";
import Alert, { AlertType } from "../components/common/Alert";

export default function Login() {
  const [email, setEmail] = useState();
  const [error, setError] = useState<any>(false);
  const [success, setSuccess] = useState<any>(false);

  const submit = async (e: any) => {
    e.preventDefault();

    setError(false);
    setSuccess(false);

    fetch(`${process.env.NEXT_PUBLIC_API_URL}/login`, {
      method: "POST",
      credentials: "include",
      //@ts-ignore ???? - these headers are needed for nextjs to pass the cookie to the api
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({ email }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          setError(res.error);
        } else {
          setSuccess(res.message);
        }
      });
  };

  // TODO: maybe we can hit authenticate endpoint to check cookie and save some emails
  return (
    <div className="p-4 flex justify-center">
      <main className="flex flex-col items-center py-8 gap-10 bg-slate-50 border border-slate-500 drop-shadow-2xl md:w-3/4">
        <h1 className="text-4xl md:text-5xl font-bold">
          Welcome back, enter your email below to login
        </h1>

        <div className="flex flex-col px-12">
          <form onSubmit={(e) => submit(e)}>
            <input
              type="email"
              required
              className="w-full p-1 bg-white text-black border border-3 border-grey-500 rounded p-1 focus:ring-4 focus:ring-sky-500"
              placeholder="Enter your email"
              onChange={debounce((e) => setEmail(e.target.value), 500)}
            />

            <button
              className="w-full my-4 py-4 text-white font-bold bg-sky-700 rounded-md hover:bg-sky-800"
              type="submit"
            >
              Send me a login link
            </button>
          </form>

          {error ? <Alert type={AlertType.ERROR} message={error} /> : null}
          {success ? (
            <Alert type={AlertType.SUCCESS} message={success} />
          ) : null}
        </div>
      </main>
    </div>
  );
}
